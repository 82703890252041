

export const getRegionOptions = (regionsArr) => regionsArr
    ?.map(region => ({ name: `${region.regionName} ${region.federalUnit}`, value: region.id }))
    || []

export const getUserRegionsOptions = (regionsArr, userRegions) => regionsArr
    ?.filter(region => userRegions.includes(region.id))
    .map(region => ({ name: `${region.regionName} ${region.federalUnit}`, value: region.id }))
    || []

export const getRegionDepsOptions = (regionsDepsArr) => regionsDepsArr
    ? regionsDepsArr.map(regionDep => ({ name: regionDep.name, value: regionDep.id }))
    : []

export const getUserRegionDepsOptions = (regionDepsArr, userRegions) => regionDepsArr
    ? regionDepsArr
        .filter(regionDep => userRegions.includes(regionDep.regionId))
        .map(regionDep => ({ name: regionDep.name, value: regionDep.id }))
    : []
