import * as types from "../actions/types";

const initialState = {
  analytical_report: null,
  analytical_details: [],
  activity_rating: null,
  contacts_report: {},
  population_report: {
    population_report: null,
    district: null
  },
  allData: [],
  detailsLoading: false,
  loading: false,
  loadingAll: null,
  hash: '',
  loadingHash: false,
  check: '',
  check_load: false,
  newData: [],
  newData_load: false
};

export default function reports (state = initialState, action) {
  switch (action.type) {
    case types.SET_ANALYTICAL_REPORT_DATA:
      return {
        ...state,
        analytical_report: action.payload,
      };
    case types.SET_ANALYTICAL_REPORT_DETAILS:
      const newDetailsList = action.payload
        ? [...state.analytical_details, action.payload]
        : []
      return {
        ...state,
        analytical_details: newDetailsList
      };
    case types.SET_ACTIVITY_RATING_DATA:
      return {
        ...state,
        activity_rating: action.payload,
      };
    case types.SET_ACTIVITY_RATING_DATA_ALL:
      return {
        ...state,
        allData: action.payload,
      };
    case types.SET_REPORT_LOADING_ALL:
      return {
        ...state,
        loadingAll: action.payload,
      };
    case types.SET_ACTIVITY_RATING_DATA_NEW:
      return {
        ...state,
        hash: action.payload,
      };
      case types.SET_REPORT_LOADING_NEW:
      return {
        ...state,
        loadingHash: action.payload,
      }; 
    case types.SET_CHECK_STATUS:
      return {
        ...state,
        check: action.payload,
      };
      case types.SET_CHECK_STATUS_LOADING:
      return {
        ...state,
        check_load: action.payload,
      };
    case types.SET_ACTIVITY_RATING_DATA_DOWNLOAD:
      return {
        ...state,
        newData: action.payload,
      };
      case types.SET_ACTIVITY_RATING_DATA_DOWNLOAD_LOADING:
      return {
        ...state,
        newData_load: action.payload,
      };
    case types.SET_POPULATION_REPORT_DATA:
      return {
        ...state,
        population_report: action.payload,
      };
    case types.ADD_ACTIVITY_RATING_DATA:
      return {
        ...state,
        activity_rating: [...state.activity_rating, ...action.payload]
      };
    case types.SET_REPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_DEPARTMENT_CONTACTS:
      const {key, data} = action.payload
      return {
        ...state,
        contacts_report: {...state.contacts_report, [key]: data}
      };
    case types.SET_REPORT_DETAILS_LOADING:
      return {
        ...state,
        detailsLoading: action.payload,
      };
    default:
      return state;
  }
}
