import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { AddEditModal, YunarmiansModal } from './Components'
import { useDispatch, useSelector } from 'react-redux'
import { clearCurrentPetition, getCurrentPetition, updatePetition, updatePetitionStatus } from '../../actions/petitions'
import { useHistory, useParams} from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import FileLabel from '../../components/FileLabel/FileLabel'
import { BACKEND_DOMAIN, PETITION_STATUSES } from '../../utils/const'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed'
import { IoMdCreate } from 'react-icons/io'
import CustomSelect from '../../components/CustomSelect/CustomSelect'





const Current = () => {
  
  const dispatch = useDispatch()
  const { current: data, loading } = useSelector(state => state.petitions)
  const { nrsh, agsh, superuser } = useSelector(state => state.auth.user)
  
  const {petitionId} = useParams()
  const history = useHistory()

  const [editModal, setEditModal] = useState(false)
  
  useEffect(() => {
    dispatch(getCurrentPetition(petitionId)) 
    return () => dispatch(clearCurrentPetition())
  }, [])
  
  const haveRights = nrsh || agsh || superuser

  const renderFile = (file) => {
    const fileLink = `${BACKEND_DOMAIN || ''}/${file.path}/${file.name}`
    return (
      <FileLabel
        fileName={file.name}
        fileLink={fileLink}
        imageMode={file.file_type.includes('image')}
        pdfMode={file.file_type.includes('pdf')}
      />
    )
  }

  const goBackHandler = () => history.goBack()

  const statusChangeHandler = (e) => {
    dispatch(updatePetitionStatus(petitionId, e.target.value))
  }

  if (!haveRights) return <h1 style={{color: '#851818', marginTop: '5rem'}}>Недостаточно прав</h1>

  if (loading || !data) return <Loader/>

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Ходатайство о награждении
        <IoMdCreate onClick={() => setEditModal(true)}/>
      </div>
      <div className={styles.petitionWrapper}>
        <div>
          <b>Региональное отделение:</b>
          <span>{data.region_department_name}</span>
        </div>
        <div>
          <b>Награда:</b>
          <span>{data.award_name}</span>
        </div>
        <div>
          <b>Файлы:</b>
          <div className={styles.files}>{data.files.map(renderFile)}</div>
        </div>
        <div>
          <b>Статус:</b>
          <CustomSelect
            selwidth='fit-content'
            withoutFirst
            options={PETITION_STATUSES.map((status) => ({name: status, value: status}))}
            selectedValue={data.status}
            onChange={statusChangeHandler}
          />
          {/* <span>{data.status}</span> */}
        </div>
        <div>
          <b>Количество претендентов:</b>
          <span>{data.profiles.length}</span>
        </div>

        <YunarmiansModal
          actualList={data.profiles}
          petitionId={petitionId}
          regDepId={data.region_department_id}
        />
        
        <div className={styles.modalButtons}>
          <ButtonRed onClick={goBackHandler}>Выйти</ButtonRed>
        </div>
      </div>
      <AddEditModal
        open={editModal}
        closeModal={() => setEditModal(false)}
        addMode={false}
        data={data}
      />
    </div>
  )
}

export default Current