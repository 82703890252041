import jsPDF from "jspdf";
import addFont from "../../fonts/fonts";
import moment from "moment";

const addFooters = (doc, img, userName) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    doc.setFontSize(6);
    doc.setPage(i);
    doc.addImage(img, "png", 5, 5, 25, 25);
    doc.text(`Отчет составлен пользователем: ${userName} ${moment().format("DD.MM.YYYY HH:mm")}`, 150, 205, "center")
    doc.setFontSize(10).text(`ВСЕРОССИЙСКОЕ ДЕТСКО-ЮНОШЕСКОЕ\nВОЕННО-ПАТРИОТИЧЕСКОГО ДВИЖЕНИЯ "ЮНАРМИЯ"`, 150, 10, "center");
  }
};

export const createDocData = async ({logo, districtName, userName}) => {
  
  let lastY = 30
  const orientation = 'l'
  
  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height) {
      doc.addPage('a4', orientation)
      newY = 10
    }
    lastY = newY
    return newY
  }

  addFont(jsPDF.API);

  const doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: orientation,
  });

  const img = new Image();
  img.src = logo;

  doc.setFont("Montserrat");

  doc.text("ЧИСЛЕННОСТЬ ЮНАРМЕЙЦЕВ ПО РЕГИОНАМ", 150, lastY, "center")
  districtName && doc.setFontSize(12).text(districtName, 150, getY(7), "center")
  doc.setFontSize(10).text(`по состоянию на ${moment().format("DD.MM.YYYY")} года`, 150, getY(7), "center")
  
  doc.autoTable({
    html: `#populationReportTable`,
    margin: {
      top: 30
    },
    styles: { font: 'Montserrat', fontSize: 8 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
    },
    bodyStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
      textColor: '#000',
    },
    footStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
    },
    showFoot: "lastPage",
    startY: getY(5)
  })

  addFooters(doc, img, userName);

  doc.addPage("a4", orientation);
  doc.deletePage(doc.getCurrentPageInfo().pageNumber);
  
  return doc;
};