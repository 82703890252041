import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPopulationReport } from '../../actions/reports'
import { selects } from '../../utils/const'
import Loader from '../../components/Loader/Loader'
import styles from './styles.module.css'
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import logo from "../../assets/images/label.png";
import moment from 'moment'
import { getFederalDistricts } from '../../actions/users'
import CustomSelect from '../../components/CustomSelect/CustomSelect'
import CustomInput from '../../components/CustomInput/CustomInput'
import { useFormik } from 'formik'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed'
import { objFilter } from '../../utils/smallHelpers'
import { getExcelFromTable } from '../../utils/excel'
import { createDocData } from './pdf'



const initialValues = {
  district: '',
  age_from: 8,
  age_to: 17,
  period_from: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
  period_to: moment().format('YYYY-MM-DD'),
}

export default function PopulationReport () {
  
  const dispatch = useDispatch()
  const {population_report: {population_report: reportData, district}, loading} = useSelector(state => state.reports)
  const {displayname: userName} = useSelector(state => state.auth)
  const {federalDistricts} = useSelector(state => state.users)
  
  const [first, setFirst] = useState(true)
  const [population_report, setPopulationReport] = useState(null)

  useEffect(() => {
    !first && setPopulationReport(reportData)
    setFirst(false)
  }, [reportData])
  

  useEffect(() => {
    dispatch(getFederalDistricts())
    // handleSubmit()
  }, [])

  const onSubmit = (values) => {
    const params = objFilter(values)
    dispatch(getPopulationReport(params))
  }

  const {values, handleChange, handleSubmit} = useFormik({ initialValues, onSubmit })

  
  const statuses = [...selects.status.slice(0, selects.status.length-1), { name: 'Прирост', value: 'increase_profiles' }]
  const {population: data, ...total} = population_report || {}

  const renderHeaderCell = ({name}) => <th>{name}</th>
  
  const renderRowCell = (regionData) => ({value}) => <td>{regionData[value || 'all']}</td>

  const renderRow = ({name, ...regionData}) => {
    const cellStyle = {textAlign: name ? 'left' : 'center'}
    return (
      <tr>
        <th style={cellStyle}>{name || 'Итого:'}</th>
        {statuses.map(renderRowCell(regionData))}
      </tr>
    )
  }

  const toPdf = () => {
    createDocData({logo, districtName, userName}).then((doc) => doc.save(`Численность по регионам.pdf`));
  };

  const toExcel = () => {
    const table = document.getElementById('populationReportTable')
    getExcelFromTable(table, 'Численность по регионам')
  }

  const Table = () =>
    <>
      <div className={styles.reportButtons}>
        <AiOutlineFilePdf title='Сохранить в PDF' color='#fa0c00' size={35} onClick={toPdf}/>
        <AiOutlineFileExcel title='Сохранить в Excel' color='green' size={35} onClick={toExcel}/>
      </div>
      <table id='populationReportTable' className={styles.reportTable}>
        <thead>
          <tr>
            <th>Регион</th>
            {statuses.map(renderHeaderCell)}
          </tr>
        </thead>
        <tbody>
          {data.map(renderRow)}
          {renderRow(total)}
        </tbody>
      </table> 
    </>
  
  const districtOptions = federalDistricts?.map(({name, id}) => ({name, value: id}))
  const allDistrictOptions = districtOptions ? [{name: 'Все', value: ''}, ...districtOptions] : []

  const districtName = federalDistricts?.find(({id}) => id == district)?.name

  return (
    <div className={styles.wrapper}>
      
      <div className={styles.filters}>
        
        <CustomSelect
          name='district'
          value={values.district}
          title='Федеральный округ'
          options={allDistrictOptions}
          onChange={handleChange}
          selwidth='100%'
          selMaxWiddth='none'
          withoutFirst
        />

        <CustomInput
          name='age_from'
          value={values.age_from}
          title='Возраст от'
          onChange={handleChange}
          type='number'
        />

        <CustomInput
          name='age_to'
          value={values.age_to}
          title='Возраст до'
          onChange={handleChange}
          type='number'
        />

        <CustomInput
          name='period_from'
          value={values.period_from}
          title='Период с'
          onChange={handleChange}
          type='date'
        />

        <CustomInput
          name='period_to'
          value={values.period_to}
          title='Период по'
          onChange={handleChange}
          type='date'
        />

        <ButtonRed onClick={handleSubmit}>Применить</ButtonRed>

      </div>

      {loading
        ? <Loader top='50%' left='48%'/>
        : data &&
          <>
            <h3>Численность по регионам</h3>
            <h3>{districtName || ''}</h3>
            <Table/>
          </>
      }

    </div>
  )
}
