import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from '../styles.module.scss'
import { IoIosClose, IoMdCreate } from 'react-icons/io'
import { useFormik } from 'formik'
import CustomInput from '../../../components/CustomInput/CustomInput'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import FileLabel from '../../../components/FileLabel/FileLabel'
import { BACKEND_DOMAIN } from '../../../utils/const'
import { useDispatch, useSelector } from 'react-redux'
import { updatePetitionProfiles, updateProfileFromPetition } from '../../../actions/petitions'
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { getAllYunarmiansData, getYunarmiansData } from '../../../actions/yunarmiyans'
import Loader from '../../../components/Loader/Loader'
import { getDepartments } from '../../../actions/departments'


const YunarmiansModal = ({actualList, petitionId, regDepId}) => {
  
  const dispatch = useDispatch()
  const {data: searchProfiles, loading} = useSelector(state => state.yunarmians)
  const {local_departments, detachments} = useSelector(state => state.departments)


  const [modalOpen, setModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)

  const [actualProfiles, setActualProfiles] = useState(actualList || [])
  const [filters, setFilters] = useState({lastName: '', firstName: '', fathersName: '', detachment: '', regions: [], localDep: [], country: [], age: []})

  useEffect(() => {
    dispatch(getDepartments(regDepId))
  }, [])
  
  useEffect(() => {
    const [localDep] = filters.localDep
    if (!localDep) return;
    dispatch(getDepartments(localDep.id, true))
  }, [filters.localDep])
  

  useEffect(() => {
    dispatch(getYunarmiansData(1, filters))
  }, [filters])
  
  const saveProfilesHandler = async () => {
    await dispatch(updatePetitionProfiles(petitionId, actualProfiles))
    setModalOpen(false)
  }

  const renderProfile = (profile) => {
    const descr = profile.award_reason ? `(${profile.award_reason})` : ''
    return (
      <li key={profile.id} onClick={() => setSelected(profile)}>
        {profile.fio}
        <em> {descr}</em>
      </li>
    )
  }
  
  const removeProfileHandler = (index) => () => {
    const newProfiles = [...actualProfiles]
    newProfiles.splice(index, 1)
    setActualProfiles(newProfiles)
  }

  const renderActualProfile = (profile, index) => (
    <li>
      <span>{profile.fio}</span>
      <IoIosClose
        color='red'
        size={32}
        onClick={removeProfileHandler(index)}
        title='Убрать из списка'
      />
    </li>
  )
  
  const selectProfileHandler = (profile) => () => {
    const exist = actualProfiles.find(p => p.profile_id === profile.profile_id)
    if (exist) return;
    setActualProfiles([...actualProfiles, profile])
  }

  const renderSearchProfile = (profile) => {
    const fio = `${profile.lastName} ${profile.firstName} ${profile.fathersName}`
    const data={fio, profile_id: profile.id}
    return (
      <li onClick={selectProfileHandler(data)}>{fio}</li>
    )
  }

  const inputChangeHandler = (fieldName) => (value) => {
    setFilters({...filters, [fieldName]: value})
  }

  const setSelectValueHandler = (e) => {
    const {name, value} = e.target
    const newValue = name === 'detachment' ? value : (value ? [{id: value}] : [])
    setFilters({...filters, [name]: newValue})
  }

  const renderOption = ({id, name}) => <option key={id} value={id}>{name}</option>

  return (
    <div className={styles.peoples}>
      <b>Претенденты:</b>
      <span><IoMdCreate onClick={() => setModalOpen(true)} /></span>
      <ul>{actualList.map(renderProfile)}</ul>
      
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        classNames={{ modal: styles.yunarmiansModal }}
        showCloseIcon={false}
      >
        <ul>{actualProfiles?.map(renderActualProfile)}</ul>
        <div>

          <select
            name='localDep'
            value={filters.localDep[0]?.id}
            onChange={setSelectValueHandler}
          >
            <option value=''>Местное отделение (все)</option>
            {local_departments?.map(renderOption)}
          </select>

          <select
            name='detachment'
            value={filters.detachment}
            onChange={setSelectValueHandler}
          >
            <option value=''>Отряд (все)</option>
            {detachments?.map(renderOption)}
          </select>

          <SearchInput placeholder='Фамилия' onChange={inputChangeHandler('lastName')}/>
          <SearchInput placeholder='Имя' onChange={inputChangeHandler('firstName')}/>
          <SearchInput placeholder='Отчество' onChange={inputChangeHandler('fathersName')}/>
          {
            loading
              ? <div style={{height: '100px'}}><Loader left='190px'/></div>
              : <ul>{searchProfiles?.map(renderSearchProfile)}</ul>
          }
        </div>
        <div>
          <ButtonRed onClick={() => setModalOpen(false)}>Закрыть</ButtonRed>
          <ButtonRed onClick={saveProfilesHandler}>Сохранить</ButtonRed>
        </div>
      </Modal>
      
      <PeopleModal
        data={selected}
        closeModal={() => setSelected(null)}
      />
    </div>
  )
}


const PeopleModal = ({data, closeModal}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    data && setValues(data)
  }, [data])
  
  const updatePeopleData = async (values) => {
    const { created_at, created_by, updated_at, updated_by, fio, ...payload } = values
    await dispatch(updateProfileFromPetition(payload, data.files))
    closeModal()
  }
  
  const {
    values,
    errors,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: {},
    onSubmit: updatePeopleData
  })

  const renderFile = (file, index) => {
    const added = !file.id
    const fileLink = added ? URL.createObjectURL(file) : `${BACKEND_DOMAIN || ''}/${file.path}/${file.name}`
    const imageMode = added ? file.type.includes('image') : file.file_type.includes('image')
    const pdfMode = added ? file.type.includes('pdf') : file.file_type.includes('pdf')
    return (
      <FileLabel
        fileName={file.name}
        fileLink={fileLink}
        imageMode={imageMode}
        pdfMode={pdfMode}
        onDelete={deleteFileHandler(index)}
      />
    )
  }

  const addFilesHandler = (e) => {
    const files = Object.values(e.target.files)
    setFieldValue('files', [...values.files, ...files])
  }

  const deleteFileHandler = (index) => () => {
    const newFilesList = values.files.filter((it, i) => i !== index)
    setFieldValue('files', newFilesList)
  }

  const onAddFilesClick = () => {
    const input = document.getElementById('inputFiles')
    input.click()
  }

  return (
    <Modal
      open={!!data}
      onClose={closeModal}
      classNames={{ modal: styles.profileModal }}
      showCloseIcon={false}
    >
      <div className={styles.title}>{values.fio}</div>
      <form onSubmit={handleSubmit} className={styles.modalBody}>
        <CustomInput
          title='За что награждается'
          name='award_reason'
          value={values.award_reason || ''}
          onChange={handleChange}
        />
        <CustomInput
          title='Причина отказа'
          name='award_reject'
          value={values.award_reject || ''}
          onChange={handleChange}
        />
        <div className={styles.files}>
          <p>Файлы:</p>
          <div>{values.files?.map(renderFile)}</div>
          <input multiple hidden id='inputFiles' type='file' onChange={addFilesHandler}/>
          <ButtonRed type='button' onClick={onAddFilesClick}>Добавить</ButtonRed>
        </div>
        <div>
          <ButtonRed type='submit'>Сохранить</ButtonRed>
          <ButtonRed type='button' onClick={closeModal}>Закрыть</ButtonRed>
        </div>
      </form>
    </Modal>
  )
}

const SearchInput = ({ onChange, ...props }) => {
  
  const [searchString, setSearchString] = useState('')
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (!searchString && firstRender) return;
    firstRender && setFirstRender(false)
    const timeout = setTimeout(() => {
      onChange(searchString)
    }, 1000);
    return () => {
      clearTimeout(timeout)
    }
  }, [searchString])
  
  return (
    <label className={styles.searchInput}>
        <AiOutlineSearch/>
        <input
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          {...props}
        />
        {searchString &&
        <AiOutlineClose onClick={() => setSearchString('')} />}
    </label>
  )
}

export default YunarmiansModal