import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { ERROR, SET_PETITIONS_CURRENT, SET_PETITIONS_LIST, SET_PETITIONS_LOADING } from "./types.js";
import { objFilter } from "../utils/smallHelpers.js";
import { createNotification } from "../components/Notifications/Notifications.jsx";


export const clearCurrentPetition = () => ({type: SET_PETITIONS_CURRENT, payload: null});

export const getPetitions = (filters) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_PETITIONS_LOADING, payload: true})
    const filteredPArams = objFilter(filters)
    const urlParams = new URLSearchParams(filteredPArams).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({type: SET_PETITIONS_LIST, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
    dispatch({type: SET_PETITIONS_LOADING, payload: false})
  }
};

export const getCurrentPetition = (petId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_PETITIONS_LOADING, payload: true})
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({type: SET_PETITIONS_CURRENT, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
    dispatch({type: SET_PETITIONS_LOADING, payload: false})
  }
};

export const createPetition = (data) => async (dispatch, getState) => {
  let petId
  try {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({type: SET_PETITIONS_LOADING, payload: true})
      const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports`, {
        method: "POST",
        ...buildDefaultHeaders(getState),
        body: JSON.stringify(data)
      });
      if (response.ok) {
        const respData = await response.json()
        petId = respData.id
        await Promise.all(data.files?.map(file => dispatch(createPetitionFile(petId, file))))
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  } catch (ERR) {

  } finally {
    dispatch({type: SET_PETITIONS_LOADING, payload: false})
    return petId
  }
};

export const updatePetition = (petId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_PETITIONS_LOADING, payload: true})
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data)
    });
    if (response.ok) {

      const originalFiles = getState().petitions.current.files

      const actualIds = data.files?.filter(file => file.id).map(file => file.id)
      const filesForUpload = data.files?.filter(file => !file.id)
      const filesForDelete = originalFiles?.filter(file => !actualIds?.includes(file.id))

      await Promise.all(filesForUpload?.map(file => dispatch(createPetitionFile(petId, file))))
      await Promise.all(filesForDelete?.map(file => dispatch(deletePetitionFile(petId, file.id))))

    } else {
      dispatch({ type: ERROR, payload: response });
    }
    await dispatch(getCurrentPetition(petId))
    dispatch({type: SET_PETITIONS_LOADING, payload: false})
  }
};

export const updatePetitionStatus = (petId, status) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_PETITIONS_LOADING, payload: true})
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify({status})
    });
    const notifType = response.ok ? "success" : "error"
    const notifText = response.ok ? "Статус успешно обновлен" : "Ошибка обновления статуса"
    createNotification(notifType, notifText)
    dispatch({type: SET_PETITIONS_LOADING, payload: false})
  }
};

export const deletePetition = (petId, filters) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_PETITIONS_LOADING, payload: true})
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPetitions(filters))
    } else {
      dispatch({ type: ERROR, payload: response });
    }
    dispatch({type: SET_PETITIONS_LOADING, payload: false})
  }
};

export const updatePetitionProfiles = (petId, data) => async (dispatch, getState) => {  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const initialProfiles = getState().petitions.current.profiles
    const addProfiles = data.filter(({id}) => !id)
    const actualIds = data.filter(({id}) => id).map(({id}) => id)
    const deleteProfiles = initialProfiles.filter(({id}) => !actualIds.includes(id))

    addProfiles.length && await dispatch(addProfilesToPetition(petId, addProfiles))
    await Promise.all(deleteProfiles.map(({profile_id}) => dispatch(deleteProfileFromPetition(petId, profile_id))))

    await dispatch(getCurrentPetition(petId))
  }
}

export const addProfilesToPetition = (petId, data) => async (dispatch, getState) => {
  await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}/profiles`, {
    method: "POST",
    ...buildDefaultHeaders(getState),
    body: JSON.stringify(data)
  });
};

export const deleteProfileFromPetition = (petId, profileId) => async (dispatch, getState) => {
  await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}/profiles/${profileId}`, {
    method: "DELETE",
    ...buildDefaultHeaders(getState),
  });
}

export const updateProfileFromPetition = (data, prevFiles) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({type: SET_PETITIONS_LOADING, payload: true})
    const {files, profile_id, awards_report_id, id, ...payload} = data
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${awards_report_id}/profiles/${profile_id}`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(payload)
    });
    if (response.ok) {

      const actualIds = files?.filter(file => file.id).map(file => file.id)
      const filesForUpload = files?.filter(file => !file.id)
      const filesForDelete = prevFiles?.filter(file => !actualIds?.includes(file.id))

      await Promise.all(filesForUpload?.map(file => dispatch(createPeopleFile(id, file))))
      await Promise.all(filesForDelete?.map(file => dispatch(deletePeopleFile(id, file.id))))

      await dispatch(getCurrentPetition(awards_report_id))
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }  
}

export const createPetitionFile = (petId, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const fd = new FormData();
    fd.append("file", file);
    await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}/file`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
      body: fd
    });
  }
}

export const deletePetitionFile = (petId, fileId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
  await fetch(`${BACKEND_URL_PREFIX}/awards_reports/${petId}/file/${fileId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
  }
}

export const createPeopleFile = (entityId, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const fd = new FormData();
    fd.append("file", file);
    await fetch(`${BACKEND_URL_PREFIX}/awards_reports/profiles/${entityId}/file`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
      body: fd
    });
  }
}

export const deletePeopleFile = (entityId, fileId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
  await fetch(`${BACKEND_URL_PREFIX}/awards_reports/profiles/${entityId}/file/${fileId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
  }
}

export const groupStatusUpdate = (data, filters) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/awards_reports`, {
      method: "PUT",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data),
    });
    if (response.ok) {
      await dispatch(getPetitions(filters))
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};