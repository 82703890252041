/* eslint-disable */
import React, {  useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMaterials, deleteMaterial, addMaterial, editMaterial, getGroups, getFilesList, uploadFile, deleteFile } from "../../actions/methodicMaterials";
import deleteIcon from "../../assets/images/delete.png";
import MethodicMaterialsModal from "./MethodicMaterialsModal";
import styles from "./MethodicMaterials.module.css"
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";

const MethodicMaterials = ({
  getMaterials,
  getGroups,
  getFilesList,
  deleteMaterial,
  addMaterial,
  editMaterial,
  materials,
  groups,
  filesList,
  accessRights,
  uploadFile,
  deleteFile,
  deleting=false,
  token
}) => {
  
  const deleteRights = accessRights.ac_delete

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [sort, setSort] = useState("");


  useEffect(() => {
    getMaterials(sort)
    !groups && getGroups()
  }, [sort]);

  const openModal = (type, item) => () => {
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteGroup = (id) => {
    deleteMaterial(id)
  }

  const acceptConfirmation = () => {
    handleDeleteGroup(confirmation.id)
    setConfirmation(null)
  }

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Методические материалы</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {accessRights.ac_create && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Материал</div>
            <div className={styles.username}>Группа</div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {materials &&
              materials.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={openModal("EDIT", a)}
                  >
                    <span>
                      {a.name}
                    </span>
                  </div>
                  <div className={styles.username}>{a.groupname}</div>
                  <div className={styles.superuser}></div>
                  <div className={styles.actions}>
                    {deleteRights
                      ? <IoMdClose onClick={() => setConfirmation({text: a.name, id: a.id})}/>
                      : <div style={{height:'1.5rem'}}></div>
                    }
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.controls}>
          <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
          </div>
        </div>
      </div>
      {confirmation && <Confirmation 
              confirmation={confirmation.text}
              acceptConfirmation={acceptConfirmation}
              rejectConfirmation={() => setConfirmation(null)}

      />}
      {isModalOpen &&
      <MethodicMaterialsModal
        token={token}
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        filesList={filesList}
        addMaterial={addMaterial}
        getFilesList={getFilesList}
        accessRights={accessRights}
        editMaterial={editMaterial}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        groups={groups}
      />}
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  materials: state.materialGroups.materials,
  groups: state.materialGroups.groups,
  filesList: state.materialGroups.files_list,
  lastUpdate: state.schedule.lastUpdate,
  accessRights: state.auth.accessRights.find(i => i.name==='teaching_material')||{}
})

export default connect(mapStateToProps, { getMaterials, getGroups, getFilesList, deleteMaterial, addMaterial, editMaterial, uploadFile, deleteFile })(
  MethodicMaterials
);
