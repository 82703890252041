import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from '../Tasks.module.css'
import Loader from '../../../components/Loader/Loader'
import CustomInput from '../../../components/CustomInput/CustomInput'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import NoData from '../../../components/NoData/NoData'
import TaskRealization from './TaskRealization'
import { getRegionOptions } from '../../../utils/getOptions'
import { getExcelFromTable } from '../../../utils/excel'
import { RiFileExcel2Line } from "react-icons/ri";
import { selects } from '../../../utils/const'
import moment from 'moment'


const taskStatus = [
    {name: "Выполнение подтверждено", value: "success"},
    {name: "Выполнение некорректно", value: "failed"},
    {name: "Не проверено", value: "not_checked"},
]

const initialFilters = {
  name: "",
  regionId: "all",
  domestic_department: "all",
  detachment: "all",
  status: "all"
}

export default function TaskProfiles({
    goBack,
    id,
    getTaskProfiles,
    getDepartments,
    getDepartmentsByRegion,
    changeCompleteStatus,
    getTaskRealization,
    realizationMode, 
    stepsData,
    setRealizationMode
}) {
  
  const [data, setData] = useState(null)
  const [params, setParams] = useState(initialFilters)

  const { loading, task_profiles: profiles } = useSelector(state => state.task)
  const { regions } = useSelector(state => state.auth)
  const { depsByReg, detachments } = useSelector(state => state.departments)

  const profileStatuses = selects.status.reduce((acc, item) => ({...acc, [item.value]: item.name}), {})

  useEffect(() => {
    getTaskProfiles(id, params)
  }, [params])
  
  useEffect(() => {
    profiles && setData([...profiles])
  }, [profiles])
  
  const handleChangeCompleteStatus = (ind, profileObj) => {
    const arr = [...data]
    arr[ind] = profileObj
    setData(arr)
    changeCompleteStatus(profileObj)
  }

  const handleParamsChange = (e) => {
    setParams(prev => ({...prev, [e.target.name]: e.target.value}))
  }
  
  const handleChangeRegion = (e) => {
    const {value} = e.target
    handleParamsChange(e)
    setParams(prev => ({...prev, domestic_department: "all", detachment: "all"}))
    value !== 'all' && getDepartmentsByRegion(value)
  }
  
  const handleChangeDomDep = (e) => {
    const {value} = e.target
    handleParamsChange(e)
    setParams(prev => ({...prev, detachment: "all"}))
    value !== 'all' && getDepartments(value, true)
  }

  const dateFormatted = (date) => date ? moment(date).format('DD.MM.YYYY HH:mm') : ''

  const renderRow = (forExcel) => (item, ind) => {
    
    const stringStatus = taskStatus.find(status => status.value === item.status).name
    

    const profileStatus = profileStatuses[item.profile_status]

    const userAnswersData = item.users_links?.reduce((acc, item) => ({...acc, [item.task_step_id]: item.link}), {}) || {}

    return (
      <tr
        onClick={() => setRealizationMode(item.profile_id)}
        key={item.profile_personal_id}
      >
          <td>{ind+1}</td>
          <td>{item.profile_name}</td>
          <td>{profileStatus}</td>
          <td>{item.profile_personal_id}</td>
          <td>{item.profile_region_name}</td>
          <td>{item.detachment_name}</td>
          <td>{dateFormatted(item.passage_date)}</td>
          <td>
            {forExcel
              ? stringStatus
              : <select
                  value={item.status}
                  onChange={(e) => handleChangeCompleteStatus(ind, {...item, status: e.target.value })}
                  onClick={e => e.stopPropagation()}
                >
                    {taskStatus.map(item => <option value={item.value}>{item.name}</option>)}
                </select>
            }
          </td>
          {forExcel && stepsData.map(({id}) => <th>{userAnswersData[id]}</th>)}
      </tr>
    )
  }

  const domDepsArray = depsByReg?.map(({name, id}) => ({name, value: id})) || []
  const detachArray = detachments?.map(({name, id}) => ({name, value: id})) || []
  
  const giveExcel = () => {
    const table = document.getElementById('profile_task_table')
    getExcelFromTable(table, 'Юнармейцы выполнившие задание')
  }

  const tableHeader = (forExcel) =>
  <tr style={{backgroundColor: 'white'}}>
    <th>№</th>
    <th>ФИО</th>
    <th>Статус</th>
    <th>ЛН</th>
    <th>Регион</th>
    <th>Отряд</th>
    <th>Дата выполнения</th>
    <th>Статус выполнения</th>
    {forExcel && stepsData.map(({name}) => <th>{name}</th>)}
  </tr>

  return (
    <>
      {realizationMode
        ? <TaskRealization
            profileId={realizationMode}
            h={document.documentElement.scrollHeight}
            taskId={id}
            goBack={() => setRealizationMode(null)}
            getTaskRealization={getTaskRealization}
          />
        : 
        <></>
      }      

      <>
            <div className={styles.title}>Участики, выполнившие задание</div>
              <div className={styles.modalBody} 
              style={{
              opacity: `${realizationMode ? 0 : 1}`,
              userSelect: `${realizationMode ? 'auto' : 'none'}`,
              overflow: `${realizationMode ? 'hidden' : 'scroll'}`,
              }}>
                <div className={styles.filters}>
                  <CustomSelect
                    name="regionId"
                    title="Регион"
                    options={[{name: "Все", value: "all"}, ...getRegionOptions(regions)]}
                    selectedValue={params.regionId}
                    onChange={handleChangeRegion}
                    width='450px'
                    withoutFirst
                  />
                  <CustomSelect
                    name='domestic_department'
                    title='Местное отделение'
                    options={[{name: "Все", value: "all"}, ...domDepsArray]}
                    onChange={handleChangeDomDep}
                    selectedValue={params.domestic_department}
                    disabled={params.regionId === 'all'}
                    width='450px'
                    withoutFirst
                    />
                  <CustomSelect
                    name='detachment'
                    title='Отряд'
                    options={[{name: "Все", value: "all"}, ...detachArray]}
                    selectedValue={params.detachment}
                    disabled={params.regionId === 'all' || params.domestic_department === 'all'}
                    onChange={handleParamsChange}
                    width='450px'
                    margin='.2rem 0'
                    withoutFirst
                    />
                  <CustomInput
                    title="ФИО"
                    name="name"
                    value={params.name}
                    onChange={handleParamsChange}
                    padding='0 45px 0 0'
                    width='450px'
                    margin='.2rem 0'
                  />
                  <CustomSelect
                    name="status"
                    title="Статус"
                    options={[{name: "Все", value: "all"}, ...taskStatus]}
                    selectedValue={params.status}
                    onChange={handleParamsChange}
                    width="200px"
                    withoutFirst
                  />
                </div>
                <div className={styles.tableWrap}>
                  {loading
                    ? <Loader
                          size={60}
                          left="46%"
                          top='40%'
                      />
                    : (!!data?.length
                        ? <>
                            <table id='profile_task_table' hidden>
                              {tableHeader('forExcel')}
                              {data.map(renderRow('forExcel'))}
                            </table>
                            <table>
                              {tableHeader()}
                              {data.map(renderRow())}
                            </table>
                            <button className={styles.downloadExcel} onClick={giveExcel}>
                              <i><RiFileExcel2Line/></i>
                              <span>Скачать .xlsx</span>
                            </button>
                          </>
                        : <NoData text="данные отсутствуют" />
                      )
                  }
                </div>
                <div className="settingModal__btns">
                  <button
                    className="newsListHeader__btn btn_grey"
                    type="button"
                    onClick={goBack}>
                    Назад
                  </button>
                </div>
              </div>
          </>  
    </>
  )
}
