import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { BACKEND_DOMAIN, optionsStap } from "../../../utils/const";
import styles from "../Tasks.module.css";

export default function TaskRealization({
    taskId,
    profileId,
    goBack,
    getTaskRealization
}) {
  
    const { loading, task_realization } = useSelector(state => state.task)
    
    useEffect(() => {
        getTaskRealization(taskId, profileId)
    }, [])
    
  
    const strip = (html) => {
      var tmp = document.createElement("div");
      tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText;
    };

    const renderImageAnswer = (answer) => answer?.files?.map(el => 
      <a
        href={`${BACKEND_DOMAIN || ""}/${el.path}/${el.name}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {el.name}
      </a>
      )

    const renderLinkAnswer = (answer) => {
      if (!answer) return;
      const link = answer.answer
      const linkText = answer && ( link || `${BACKEND_DOMAIN || ''}/${answer.files[0].path}/${answer.files[0].name}` )
      return (
        link?.startsWith('https')
          ? <a
              href={linkText}
              target="_blank"
              title='Открыть ссылку'
              rel="noopener noreferrer"
            >
              {!!link
                ? <div>{linkText}</div>
                : <img src={linkText} alt="o_O"/>
              }
            </a>
          : <div title='Скопируйте и вставьте ссылку в поисковую строку'>{link}</div>
      )
    }

    const renderFileAnswer = (answer) => {
      const file = answer?.files.length && answer.files[0]
      if (!file) return;
      return (
        <a
          href={`${BACKEND_DOMAIN || ""}/${file.path}/${file.name}`}
          target="_blank"
          title='Скачать файл'
          download={file.name}
          rel="noopener noreferrer"
        >
          Файл
        </a>
      )
    }

    const renderTextAnswer = (answer) => answer?.answer

    return (
    <div class={styles.wrap}>
      <div className={styles.title}>Выполнение задания</div>
      <div className={styles.modalBody}>
        <div className={styles.realizBlock}>
            {loading ? <Loader size={60} top="40%" left="47%"/> : task_realization && task_realization.steps.length &&
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Название</th>
                        <th>Задание</th>
                        <th>Тип ответа</th>
                        <th>Результат</th>
                    </tr>
                </thead>
                {task_realization && task_realization.steps.map(item => (
                  <tr>
                      <td>{item.number}</td>
                      <td>{item.name}</td>
                      <td>{strip(item.description)}</td>
                      <td style={{textAlign: "center"}}>{optionsStap.find(it => it.value==item.result).name}</td>
                      <td>
                        {item.result === '1' && renderImageAnswer(item.answer)}
                        {item.result === '2' && renderLinkAnswer(item.answer)}
                        {item.result === '3' && renderFileAnswer(item.answer)}
                        {item.result === '4' && renderTextAnswer(item.answer)}
                      </td>
                  </tr>
                ))}
            </table>}
        </div>
        <div className="settingModal__btns" style={{marginTop: "10px"}}>
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={goBack}
          >
            Назад
          </button>
        </div>
      </div>
    </div>
  );
}
