import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import styles from "./RegionDepartments.module.scss";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { MdPictureAsPdf } from "react-icons/md";
import { IoMdTrash, IoIosClose } from "react-icons/io";
import { BACKEND_DOMAIN } from "../../utils/const";
import { MdModeEdit } from "react-icons/md";
import { getRegionOptions, getUserRegionsOptions } from "../../utils/getOptions";
import Yamap from "../../components/shared/Yamap";
import { useOnClickOutside } from '../../hooks'
import Datepicker from "../../components/CustomDatepickerNew";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentFiles } from "../../actions/departments";
import { IoMdAdd } from "react-icons/io";
import FileLabel from '../../components/FileLabel/FileLabel';
import YunprofileLinkComponent from "../../components/YunprofileLinkComponent";



const POSITIONS = ["Начальник штаба Регионального отделения", "ВРИО Начальника Штаба Регионального отделения"]
const AWARD_TYPES = [
  {name: 'Государственные', value: 'state'},
  {name: 'Ведомственные', value: 'departmental'},
  {name: 'Региональные', value: 'regional'},
  {name: 'Общественные', value: 'public'},
  {name: 'Юнармейские', value: 'yunarmy'}
]

const RegionDepartmentsModal = ({
  type,
  selectedItem,
  addDepartment,
  editDepartment,
  regions,
  uploadFile,
  deleteFile,
  loading,
  filesList = [],
  data,
  removeDataForPrint,
  getAllYunarmiansData,
  getDepartments,
  history,
  profiles,
  accessRights,
  userRegions,
  ...props
}) => {
  
  const dispatch = useDispatch()
  const { files } = useSelector(state => state.departments)
  
  const initialCheckboxes = AWARD_TYPES.reduce((acc, item) => ({...acc, [item.value]: false}), {})


  const [item, setItem] = useState(null);
  const [regionValid, setRegionValid] = useState(true);
  const [file, setFile] = useState({file: null, name: null});
  const [isDelete, setIsDelete] = useState(false);
  const [profilePopUp, setProfilePopUp] = useState(false)
  const [profileFilters, setProfileFilters] = useState({})
  const [profileName, setProfileName] = useState("Профиль не указан")
  const [filesObject, setFilesObject] = useState({})
  const [checkboxes, setCheckboxes] = useState(initialCheckboxes)



  const popUpRef = React.useRef();
  const updateRights = type==='ADD' || accessRights.ac_update || (accessRights.ac_update_by_region && userRegions.includes(selectedItem?.regionId))


  useEffect(() => {
    if (!files) return;
    setFilesObject(files)
    setCheckboxes(AWARD_TYPES.reduce((acc, item) => ({...acc, [item.value]: !!files[item.value].length}), {}))
  }, [files])

  useEffect(() => {
    setFilesObject({})
    setCheckboxes(initialCheckboxes)
    if (type === "ADD") {
      setItem({
        chief: "",
        city: "",
        email: "",
        regionId: "",
        name: "",
        phone: "",
        position: "Начальник штаба Регионального отделения",
        decision: "",
      });
    } else {
      if (!selectedItem) return;

      dispatch(getDepartmentFiles(selectedItem.id))      

      const item = {
        ...selectedItem,
        chief_election_date: modifyDate(selectedItem.chief_election_date),
        chief_reelection_date: modifyDate(selectedItem.chief_reelection_date)
      }
      setItem(item);

      setProfileName(selectedItem?.chief_id || "Профиль не указан")
    }
    setRegionValid(true)
  }, [selectedItem, loading]);

  useEffect(() => {
    const check = data && data.length && data.find(it => it.id === item?.id)
    setItem(check)
  }, [data])

  const setUserProfile = (profile) => {
    const newProfileName = profile?.id || "Профиль не указан"
    const chief_id = profile?.id || null
    setItem(prev => ({...prev, chief_id}))
    setProfileName(newProfileName)
  }
  
  const handleAddDepartment = async () => {
    if (Number(item.regionId)) {
      const obj = { ...item, parent: 0 };
      const success = await addDepartment(obj, 0, false, file, null, filesObject);
      if (!success) return;
      setItem(null);
      props.onClose();
      setFile({file: null, name: null});
    } else {
      setRegionValid(false);
    }
  };

  const modifyDate = date => date ? moment(date).format('YYYY-MM-DD') : null

  const handleEditDepartment = async () => {
    if (Number(item.regionId)) {
      const val = selectedItem?.imageurl;
      const parentVal = selectedItem.id;
      const success =  await editDepartment(item.id, item, 0, null, null, null, filesObject);
      if (!success) return;
      if (!val && file.name) {
        uploadFile(item.id, file, item.parent);
        setFile({file: null, name: null});
      } else if (val && file.name) {
          deleteFile(selectedItem.id, item.parent, false, 0, file, parentVal);
      }
    } if (isDelete == true) {
      deleteFile(selectedItem.id);
    } else {
      setRegionValid(false);
    }
    props.onClose();
    setItem(null); 
  };

  const handleFile = (e) => {
      setFile({ file: e.target.files[0], name: e.target.value});
      setIsDelete(false);
  };

  const handleDeleteFile = () => {
    setFile({file: null, name: null});
    setIsDelete(true);
  };

  const handleClose = () => {
    setItem(null);
    setFile({file: null, name: null});
    setIsDelete(false);
    props.onClose();
  }
  
  const uploadFileClick = ({target}) => {
    const inputFile = document.getElementById(`input_${target.name}`)
    inputFile.click()
  }

  const uploadFileHandler = (e) => {
    const { name, files } = e.target
    const filesList = [...(filesObject[name] || []), ...Object.values(files)]
    setFilesObject(prev => ({...prev, [name]: filesList}))
  }

  const awardCheckboxClick = (e) => {
    const { name } = e.target
    const actualValue = checkboxes[name]
    setCheckboxes({...checkboxes, [name]: !actualValue})
    actualValue && setFilesObject(prev => ({...prev, [name]: []}))
  }

  const deleteFileHandler = (fileType, index) => () => {
    const newFilesList = filesObject[fileType].filter((it, i) => i !== index)
    setFilesObject(prev => ({...prev, [fileType]: newFilesList}))
  }

  const staffInputHandler = (e) => {
    const { name, value } = e.target
    const region_department_staff = item.region_department_staff ? {...item.region_department_staff} : {}
    region_department_staff[name] = value || null
    setItem(prev => ({...prev, region_department_staff}))
  }

  const unaprofileLink = item?.chief_id && `/profile/${item.chief_id}`

  const renderFile = (fileGroup) => (file, index) => {
    const fileUrl = file && (file.id ? `${BACKEND_DOMAIN}${file.path}/${file.name}` : URL.createObjectURL(file))
    const fileType = file.file_type || file.type
    return (
      <FileLabel
        fileName={file.name}
        fileLink={fileUrl}
        imageMode={fileType.includes('image')}
        onDelete={deleteFileHandler(fileGroup, index)}
      />
    )
  }

  return (
    <Modal
      onClose={handleClose}
      open={props.open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD" ? "Добавление отделения" : "Редактирование отделения"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          <div>
            <CustomInput
              value={item?.name}
              title="Название отделения"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, name: e.target.value }))}
            />
            <CustomSelect
              options={accessRights.ac_create
                ? getRegionOptions(regions)
                : getUserRegionsOptions(regions, userRegions)
              }
              selectedValue={item.regionId}
              title="Регион"
              selwidth="100%"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, regionId: e.target.value }))
              }
              validate={!regionValid}
            />
            <CustomInput
              value={item.city}
              title="Населенный пункт"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, city: e.target.value }))
              }
            />
            <CustomInput
              value={item.address}
              title="Адрес"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, address: e.target.value }))
              }
            />

            <div className={styles.mapBlock}>
              <span className={styles.mapTitle}>Местоположение (укажите точку на карте):</span>
              <Yamap
                coordinate={item.geo}
                setItem={setItem}
                width='100%'
                height='280px'
                name='geo'
              />
            </div>

            <section className={styles.staffBlock}>
              
              <p>Руководитель отделения</p>

              <div style={{display: "flex", alignItems: "flex-start"}}>
            <CustomInput
              width="70%"
              value={item.chief}
              title="Руководитель"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, chief: e.target.value }))
              }
            />
            <YunprofileLinkComponent
              setUserProfile={setUserProfile}
              profileName={profileName}
              profileLink={unaprofileLink}
              style={{marginLeft: "1rem"}}
            />
            </div>
            <div className={styles.chiefPhotosBlock}>
              <p>Фото руководителя:</p>
              <div>
                {filesObject.chief_photo?.map((file, index) => {
                  const fileLink = file.id ? `${BACKEND_DOMAIN}${file.path}/${file.name}` : URL.createObjectURL(file)
                  return (
                    <FileLabel
                      fileLink={fileLink}
                      fileName={file.name}
                      onDelete={deleteFileHandler('chief_photo', index)}
                    />
                  )
                })}
              <div
                onClick={() => document.getElementById(`input_chief_photo`).click()}
                name='chief_photo'
                className={styles.fileModalButton}
              >
                <input
                  id='input_chief_photo'
                  type='file'
                  name='chief_photo'
                  onChange={uploadFileHandler}
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  multiple
                  hidden
                />
                <IoMdAdd size={30} color='#ced4da'/>
              </div>
              </div>
            </div>
            <CustomInput
              value={item.position}
              title="Должность"
              disabled
              onChange={(e) =>
                setItem((prev) => ({ ...prev, position: e.target.value }))
              }
            />
            <CustomCheckbox
              label='ВРИО начальника'
              value={item.position === POSITIONS[1]}
              onChange={(e) => setItem(prev => ({...prev, position: POSITIONS[Number(!(prev.position === POSITIONS[1]))]}))}
            />
            <CustomInput
              value={item.chief_workplace}
              title="Место работы (не в Юнармии)"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, chief_workplace: e.target.value }))}
            />
            <CustomInput
              value={item.chief_military_rank}
              title="Воинское звание"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, chief_military_rank: e.target.value }))}
            />
            <div className={styles.datesBlock}>
              <Datepicker
                value={item.chief_election_date}
                title="Дата избрания"
                disabled={!updateRights}
                onChange={(e) => setItem((prev) => ({ ...prev, chief_election_date: e.target.value }))}
              />
              <Datepicker
                value={item.chief_reelection_date}
                title="Дата переизбрания"
                disabled={!updateRights}
                onChange={(e) => setItem((prev) => ({ ...prev, chief_reelection_date: e.target.value }))}
              />
            </div>
            <section className={styles.awardsBlock}>
              <p>Наличие наград:</p>
              <div>
                {AWARD_TYPES.map(({name, value}) => {
                  const files = filesObject[value] || []
                  return (
                    <div className={styles.awardItem}>
                      <CustomCheckbox
                        label={name}
                        name={value}
                        value={checkboxes[value]}
                        onChange={awardCheckboxClick}
                      />
                      <div>
                        {!!files.length ? files.map(renderFile(value)) : <p>Отсутствует</p>}
                      </div>
                      <input
                        id={`input_${value}`}
                        type='file'
                        name={value}
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        onChange={uploadFileHandler}
                        hidden
                      />
                      {checkboxes[value] && <ButtonRed name={value} onClick={uploadFileClick}>Добавить файл</ButtonRed>}
                    </div>
                  )
                })}
              </div>
            </section>

            <CustomInput
              value={item.phone}
              title="Телефон"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, phone: e.target.value }))
              }
            />
            <CustomInput
              value={item.email}
              title="Email"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <CustomInput
              value={item.assistant}
              title="Помощник"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, assistant: e.target.value }))}
            />
            <CustomInput
              value={item.assistant_phone}
              title="Телефон"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, assistant_phone: e.target.value }))}
            />
            <CustomInput
              value={item.assistant_email}
              title="Email"
              disabled={!updateRights}
              onChange={(e) => setItem((prev) => ({ ...prev, assistant_email: e.target.value }))}
            />

            </section>
            

            <section className={styles.staffBlock}>
              
              <p>Штаб регионального отделения</p>
              
              <div className={styles.chiefPhotosBlock}>
                <p>Протокол учредительного собрания:</p>
                <div>
                  {filesObject.protocols_regional_headquarters?.map((file, index) => {
                    const fileLink = file.id ? `${BACKEND_DOMAIN}${file.path}/${file.name}` : URL.createObjectURL(file)
                    return (
                      <FileLabel
                        fileLink={fileLink}
                        fileName={file.name}
                        onDelete={deleteFileHandler('protocols_regional_headquarters', index)}
                      />
                    )
                  })}
                  <div
                    onClick={() => document.getElementById(`input_protocols_regional_headquarters`).click()}
                    name='protocols_regional_headquarters'
                    className={styles.fileModalButton}
                  >
                    <input
                      id='input_protocols_regional_headquarters'
                      type='file'
                      name='protocols_regional_headquarters'
                      onChange={uploadFileHandler}
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      multiple
                      hidden
                    />
                    <IoMdAdd size={30} color='#ced4da'/>
                  </div>
                </div>
              </div>
              
              <div className={styles.chiefPhotosBlock}>
                <p>Дата избрания Штаба Регионального отделения:</p>
                <div>
                  {filesObject.date_election_regional_headquarters?.map((file, index) => {
                    const fileLink = file.id ? `${BACKEND_DOMAIN}${file.path}/${file.name}` : URL.createObjectURL(file)
                    return (
                      <FileLabel
                        fileLink={fileLink}
                        fileName={file.name}
                        onDelete={deleteFileHandler('date_election_regional_headquarters', index)}
                      />
                    )
                  })}
                  <div
                    onClick={() => document.getElementById(`input_date_election_regional_headquarters`).click()}
                    name='date_election_regional_headquarters'
                    className={styles.fileModalButton}
                  >
                    <input
                      id='input_date_election_regional_headquarters'
                      type='file'
                      name='date_election_regional_headquarters'
                      onChange={uploadFileHandler}
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      multiple
                      hidden
                    />
                    <IoMdAdd size={30} color='#ced4da'/>
                  </div>
                </div>
              </div>
              
              <div className={styles.chiefPhotosBlock}>
                <p>Дата переизбрания Штаба Регионального отделения:</p>
                <div>
                  {filesObject.date_re_election_regional_headquarters?.map((file, index) => {
                    const fileLink = file.id ? `${BACKEND_DOMAIN}${file.path}/${file.name}` : URL.createObjectURL(file)
                    return (
                      <FileLabel
                        fileLink={fileLink}
                        fileName={file.name}
                        onDelete={deleteFileHandler('date_re_election_regional_headquarters', index)}
                      />
                    )
                  })}
                  <div
                    onClick={() => document.getElementById(`input_date_re_election_regional_headquarters`).click()}
                    name='date_re_election_regional_headquarters'
                    className={styles.fileModalButton}
                  >
                    <input
                      id='input_date_re_election_regional_headquarters'
                      type='file'
                      name='date_re_election_regional_headquarters'
                      onChange={uploadFileHandler}
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      multiple
                      hidden
                    />
                    <IoMdAdd size={30} color='#ced4da'/>
                  </div>
                </div>
              </div>

              <CustomInput
                title='Адрес'
                name='address'
                value={item.region_department_staff?.address}
                onChange={staffInputHandler}
              />

              <CustomInput
                title='Орган исполнительной власти субъекта РФб курирующий юнармию (ФИО)'
                name='executive_authority_fio'
                value={item.region_department_staff?.executive_authority_fio}
                onChange={staffInputHandler}
              />

              <CustomInput
                title='Email'
                name='executive_authority_email'
                value={item.region_department_staff?.executive_authority_email}
                onChange={staffInputHandler}
              />

              <CustomInput
                title='Номер'
                name='executive_authority_number'
                value={item.region_department_staff?.executive_authority_number}
                onChange={staffInputHandler}
                type='number'
                onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
              />
              

            </section>

            <CustomInput
              value={item.decision}
              title="Основание для создания"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, decision: e.target.value }))
              }
              max="300"
            />
            <CustomCheckbox
              label="исключить из рейтинга"
              value={item.exclude_from_rating}
              onChange={e => setItem(prev => ({...prev, exclude_from_rating: !prev.exclude_from_rating}))}
            />
            {updateRights && <div className={styles.input}>
              {(item && item !== null && item?.imageurl && !isDelete) || (file.file !== null && !isDelete) ? 
              <div className={styles.pdf}>
                <a
                  href={`${BACKEND_DOMAIN || ""}${item.imageurl}`}
                  className={styles.linkpdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={styles.imagePDF}><MdPictureAsPdf/></i>
                  <span className={styles.titlePDF}>{file?.file?.name || item?.imageurl.split('/')[item?.imageurl.split('/').length-1]}</span>
                </a>
                <i className={styles.toTrash} onClick={handleDeleteFile}><IoMdTrash/></i>
              </div>
            :
            <InputFile 
                  accept="application/pdf" 
                  title="Выберите файл для загрузки" 
                  onChange={(e) => handleFile(e)} />
              }
            </div>}
          </div>
          <div className={styles.modalButtons}>
            {updateRights &&
            <ButtonRed
              onClick={
                type === "ADD" ? handleAddDepartment : handleEditDepartment
              }
            >
              {type === "ADD" ? "Добавить" : "Сохранить"}
            </ButtonRed>}
            <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RegionDepartmentsModal;
