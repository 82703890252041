import React, { useState, useEffect } from "react";
import styles from "./ProfileCard.module.css";
import avatar from "../../assets/images/avatar.jpg";
import { IoIosInformationCircle, IoIosWoman, IoIosMan, IoMdCheckmarkCircle, IoIosTrash } from "react-icons/io";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import { BACKEND_DOMAIN, selects } from "../../utils/const";
import moment from "moment";
import vklogo from "../../assets/images/vklogo.png";
import Confirmation from "../../assets/common/Confirmation/Confirmation";


export default function ProfileCard({
  data = {},
  haveReadRights,
  superuser,
  handleAddCurrentProfile = () => {},
  rotateDocumentImage = () => {},
  deleteProfileHandler = () => {},
}) {
  const [photosHumon, setPhotosHumon] = useState(null);
  const [rotate, setRotate] = useState(0);
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const [confirmation, setConfirmation] = useState(false)


  useEffect(() => {
    if (data && data.images && data.images[0]) {
      const arr = data.images.filter((it) => it.group === "photo");
      setPhotosHumon(arr);
      arr.length && setRotate(arr[0].rotate);
    }
  }, [data]);

  const rotateImage = (direction) => {
    const angle =
      direction === "left" ? Number(rotate) - 90 : Number(rotate) + 90;
    setRotate(angle);
    rotateDocumentImage(photosHumon[0].id, angle % 360);
  };

  const acceptConfirmation = () => {
    deleteProfileHandler(data.id)
    setConfirmation(false)
  }

  const rejectConfirmation = () => {
    setConfirmation(false)
  }

  return (
    <div className={`${styles.cardWrapper} ${moment(data.birthday).add(19,'year') <= moment() ? styles.old : styles.young}` }>
      <div className={styles.firstBlock}>
        <div
          className={styles.foto}
          onMouseEnter={() => setToolbarVisible(true)}
          onMouseLeave={() => setToolbarVisible(false)}
        >
          {photosHumon && photosHumon[0] ? (
            <img
              style={{ transform: `rotate(${rotate}deg)` }}
              src={`${BACKEND_DOMAIN || ""}${photosHumon[0].path}${photosHumon[0].name}`}
              alt="o_O"
            />
          ) : (
            <img src={avatar} alt="o_O" />
          )}
          {photosHumon && photosHumon[0] && toolbarVisible && (
            <div className={styles.toolBar}>
              <GrRotateLeft onClick={() => rotateImage("left")} />
              <GrRotateRight onClick={() => rotateImage("right")} />
            </div>
          )}
        </div>
        <div className={styles.icons}>
            <div onClick={() => handleAddCurrentProfile(data)} className={styles.icon}>
            <i>
                <IoMdCheckmarkCircle size={30} color="#75c2b5"/>
            </i>
            <div style={{color: "#75c2b5"}}>Добавить в список</div>
            </div>

            <NavLink
              to={"/profile/" + data.id}
              onClick={e => !haveReadRights(data) && e.preventDefault()}
            >
            <div
              // onClick={moreInfo}
              className={styles.icon}
            >
              <i>
                <IoIosInformationCircle size={30} color="#4260e4"/>
              </i>
              <div style={{color: "#4260e4"}}>Подробнее</div>
            </div>
            </NavLink>
        </div>
      </div>
      <div className={styles.secondBlock}>
        <div className={styles.name}>
          {data.lastName}
          <br/>
          {data.firstName} {data.fathersName}<span className={styles.birthday}>{`${moment(data.birthday).format('DD.MM.YYYY')}`}</span>
          <div>
            {data.sex === "male" ? (
              <IoIosMan size={24} />
            ) : (
              <IoIosWoman size={24} />
            )}
            {superuser && data.regionId === 99 && <IoIosTrash  onClick={() => setConfirmation(true)} size={25}/>}
          </div>
        </div>
        <div className={styles.department}>
            <div className={styles.depItem}><span>Регион: </span>{data.region_name ? data.region_name : "—"}</div>
            <div className={styles.depItem}><span>Город: </span>{data.city ? data.city : "—"}</div>
            <div className={styles.depItem}><span>Местное отделение: </span>{data.domestic_department ? data.domestic_department.name : "—"}</div>
            <div className={styles.depItem}><span>Образовательная организация: </span>{data.school ? data.school : "—"}</div>
            <div className={styles.depItem}><span>Отряд: </span>{data.detachment  ? data.detachment.name : "—"}</div>
        </div>
        <div className={styles.description}>
          
          <div>
            <b>{data.city}</b>
          </div>
          <div>
            <b>Л/Н:</b>
            {data.personalId}
          </div>
          <div>
            <b>Статус:</b>
            {data.status &&
              selects.status
                .find((it) => it.value === data.status)
                ?.name.toUpperCase()}
          </div>
            {data.vkid &&
              <a
                href={"https://vk.com/" + data.vkid}
                onClick={e => !haveReadRights(data) && e.preventDefault()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={vklogo} alt='o_O'/>
              </a>
            }
        </div>
      </div>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Вы действительно хотите удалить профиль"
          confirmation={`${data.lastName} ${data.firstName}`}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  );
}
