import React, { useState } from "react";
import styles from "./EditQuestion.module.css";
import {
  IoMdCreate,
  IoIosClose,
  IoIosCheckmark,
  IoIosCloseCircle,
  IoIosArrowRoundUp
} from "react-icons/io";
import { InputFile } from "../../../../assets/common/InputFile/InputFile";
import { BACKEND_DOMAIN } from "../../../../utils/const";
import { useEffect } from "react";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import Loader from "../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import Confirmation from "../../../../assets/common/Confirmation/Confirmation";
import { createNotification } from "../../../../components/Notifications/Notifications";
import AnimationComponent from "../../../../components/AnimationComponent/AnimationComponent";

const emptyAnimation = { animation: {} }

const urlRegex = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
const httpUrlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

const questionTypes = {
  dnd: 'Перетаскивание',
  choice: 'Выбор',
  word: 'Слово',
  url: 'Ссылка',
  file: 'Файл',
  text: 'Текст',
  supplement: 'Дополнить текст' 
}

export default function EditQuestion({
  questionData,
  index,
  eventId,
  stageId,
  setTestData,
  deleteEventQuestions,
  editEventQuestions,
  getEventQuestions,
  edit,
  setEdit,
  canEdit,
  key,
  fullMode, 
  setFullMode
}) {
  
  const [data, setData] = useState({ ...questionData, removeImages: [] });
  const [filesList, setFilesList] = useState([]);
  const [localLoading, setLocalLoading] = useState(false)
  const [confirmation, setConfirmation] = useState(false)

  const {categories, animations} = useSelector(state => state.schedule)

  const correctAnswersCount = data.options?.filter(opt => opt.is_correct).length


  useEffect(() => {
    if (data.options) {
      const arr = [...data.options.sort((a, b) => parseInt(a.order_by) - parseInt(b.order_by))]
      const newAnswersArr = arr.map(option => data.answers.find(answ => answ.answer_id === option.id))
      const newAnimationsArr = arr.map(option => data.animations.find(anim => anim.answer_id === option.id) || emptyAnimation)
    const newData = {...data}
    if (correctAnswersCount>1) {
      delete newData.weight
      arr.forEach(opt => !opt.hasOwnProperty('points') && (opt.points = null))
    } else {
      if (!newData.hasOwnProperty('weight')) {
        arr.forEach(opt => delete opt.points)
        newData.weight = 5
      }
    }
    setData({...newData, options: arr, answers: newAnswersArr, animations: newAnimationsArr });
    }
  }, [correctAnswersCount])
  
  const setToggleTrue = () => {
    setEdit(index);
    setTestData(data);
  };

  const setToggleFalse = async () => {
    let errorText
    
    const dndType = data.type === 'dnd'
    const imagesLength = dndType && data.answers.filter(i => i?.answer_file).length
    const optionsLength = dndType && data.options.length
    
    imagesLength !== optionsLength && (errorText = 'Количество изображений должно соответствовать количеству вариантов ответа')
    correctAnswersCount < 2 && !data.weight && (errorText = 'Поле баллы является обязательным')

    if (errorText) {
      createNotification('error', errorText)
      return
    } else {
      setLocalLoading(true)
      const _data = { ...data };
      delete _data.id;
      delete _data.article;
      await editEventQuestions(eventId, data.id, stageId, _data, filesList);
      setEdit(null);
      setTestData(data);
      setLocalLoading(false)
    }
  };

  const cancelHandle = () => {
    setEdit(null);
    getEventQuestions(stageId);
    setFilesList([]);
  };


  const newItem = () => {
    const options = [...data.options];
    const answers = [...data.answers];
    const animations = [...data.animations];
    options.push({
      answer: "",
      is_correct: false,
      order_by: 1
    });
    answers.push({answer_file: null})
    animations.push(emptyAnimation)
    setData((prev) => ({ ...prev, options, answers, animations }));
  };

  const deleteItem = (i) => {
    const options = [...data.options];
    const answers = [...data.answers];
    const animations = [...data.animations];
    options.splice(i, 1);
    answers.splice(i, 1);
    animations.splice(i, 1);
    setData((prev) => ({ ...prev, options, answers, animations }));
  };

  const setAnswers = (i, e, orderBy) => {
    let arr = [...data.options];
    arr[i][orderBy ? 'order_by' : 'answer'] = e.target.value;
    setData((prev) => ({ ...prev, options: arr }));
  };

  const toggleAnswerCorrect = (i) => {
    const arr = [...data.options];
    arr[i].is_correct = !data.options[i].is_correct;
    setData((prev) => ({ ...prev, options: arr }));
  };

  const editAnswer = (e, i) => {
    const {name, value} = e.target
    const options = [...data.options]
    options[i][name] = value || null
    setData(prev => ({...prev, options}))
  }
  
  const deleteImage = (ind) => {
    const arr = [...data.images];
    const deleted = arr.splice(ind, 1);
    const removeArr = [...data?.removeImages];
    setData((prev) => ({
      ...prev,
      images: arr,
      removeImages: [...removeArr, deleted[0].id],
    }));
  };

  const deleteCurrentImage = (ind) => {
    const arr = [...filesList];
    arr.splice(ind, 1);
    setFilesList(arr);
  };
  
  const editImageClick = (index) => () => {
    const answIndex = index === undefined ? '' : index
    const input = document.getElementById(`inputImage${answIndex}`)
    input.click()
  }

  const deleteImageClick = (index) => () => {
    if (index===undefined) {
      setData(prev => ({...prev, question_file: null}))
    } else {
      const newData = {...data}
      newData.answers[index].answer_file = null
      setData(newData)
    }
  }

  const setNewImageFile = (index) => (e) => {
    const file = e.target.files[0]
    if (index===undefined) {
      setData(prev => ({...prev, question_file: file}))
    } else {
      const newAnswers = [...data.answers]
      newAnswers[index] = {answer_file: file}
      setData(prev => ({...prev, answers: newAnswers}))
    }
  }

  const acceptConfirmation = () => {
    deleteEventQuestions(eventId, data.id, stageId)
    setConfirmation(false)
    setEdit(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(false)
  }

  const handleAnimationChange = (ind) => (payload, descrMode) => {
    const newData = descrMode ? payload.target.value : payload
    const fieldName = descrMode ? 'description' : 'animation_id'
    const newAnimationsList = JSON.parse(JSON.stringify(data.animations))
    
    newAnimationsList[ind].animation[fieldName] = newData

    setData(prev => ({...prev, animations: newAnimationsList}))
  }

  const setQuestionFull = (e) => edit === null && setFullMode(index)
  
  const setQuestionNotFull = (e) => {
    if (fullMode && edit !== index) {
      setFullMode(null)
    }
  }

  const questionIsLink = urlRegex.test(data.question) || httpUrlRegex.test(data.question)

  const renderQuestionPreview = () => {
    
    return (
      (
        <div
          className={styles.main}
          key={key}
          id={`question${data.id}`}
          onClick={setQuestionFull}
        >
          <div className={styles.index}>{index + 1}</div>
          <div className={styles.questionPreviewBlock}>
            {questionIsLink ? <a href={data.question}>{data.question}</a> : data.question}
          </div>
          <div className={styles.correctAnswerBlock}>
            <div>Баллы:</div>
            <div>{data.weight}</div>
          </div>
          <div className={styles.questionType}>{questionTypes[data.type]}</div>
        </div>
      )
    )
  }

  const variantsLabelRender = () => {
    switch (questionData.type) {
      case "word": return 'Слово:';
      case "supplement": return 'Верные ответы:';
      default: return 'Варианты ответа:';
    }
  }


  return (
    <>
      {fullMode
        ? <div
            className={styles.main}
            key={key}
            id={`question${data.id}`}
          >
            {localLoading && <Loader top="40%" left="42%" />}
            <div className={styles.index}>{index + 1}</div>
            <div onClick={setQuestionNotFull} className={styles.questionBlock}>
              <>
                {edit === index ? (
                  <>
                    <input
                      type="file"
                      id="inputImage"
                      accept="image/png, image/jpeg"
                      onChange={setNewImageFile()}
                      hidden
                    />
                    <input
                      value={data.question}
                      className={styles.questionInput}
                      onChange={(e) =>
                        setData((prev) => ({ ...prev, question: e.target.value }))
                      }
                      autoFocus
                    />
                  </>
                ) : (
                  <div className={styles.question}>
                    {questionIsLink ? <a href={data.question}>{data.question}</a> : data.question}
                  </div>
                )}
                {(edit === index || data.question_file) &&
                <div className={styles.imageBox}>
                  {edit === index &&
                  <>
                    <IoMdCreate onClick={editImageClick()} />
                    <IoIosClose onClick={deleteImageClick()} />
                  </>}
                  <img
                    src={data.question_file
                            ? data.question_file.id
                              ? `${BACKEND_DOMAIN || ''}${data.question_file.path}/${data.question_file.name}`
                              : URL.createObjectURL(data.question_file)
                            : '/img/photoplace.png'}
                    alt='о_О'
                  />
                </div>}
              </>
              {questionData.type === "text" && (
                <div>
                  <div className={styles.imagesBlock}>
                    {data.images?.map((item, i) =>
                      edit === index ? (
                        <div className={styles.editImageItem} kei={i}>
                          <IoIosCloseCircle
                            onClick={() => deleteImage(i)}
                            size={16}
                          />
                          <img
                            src={`${BACKEND_DOMAIN || ""}/${item.path}/${item.name}`}
                            alt="o_O"
                          />
                        </div>
                      ) : (
                        <div className={styles.imageItem} key={i}>
                          <img
                            src={`${BACKEND_DOMAIN || ""}/${item.path}/${item.name}`}
                            alt="o_O"
                          />
                        </div>
                      )
                    )}
                  </div>
                  {/* {edit === index && (
                    <div>
                      <div className={styles.filesList}>
                        {!!filesList.length &&
                          filesList.map((item, i) => (
                            <div key={i}>
                              <span>{item.name}</span>
                              <span onClick={() => deleteCurrentImage(i)}>
                                <IoIosCloseCircle size={16} />
                              </span>
                            </div>
                          ))}
                      </div>
                      <InputFile
                        multiple
                        title="Выберите изображение"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                          setFilesList(Object.values(e.target.files));
                        }}
                      />
                    </div>
                  )} */}
                </div>
              )}
              {["choice", "dnd", "word", "supplement"].includes(questionData.type) && (
                <div className={styles.answers}>
                  <div>{variantsLabelRender()}</div>
                  {!data.hasOwnProperty('weight') &&
                  <div className={styles.pointsRow}>Баллы</div>}
                  {data.options?.map((item, i) => {
                    const answerFile = data.answers[i]?.answer_file
                    const imageSrc = answerFile && answerFile.name
                                      ? answerFile.id
                                        ? `${BACKEND_DOMAIN || ''}${answerFile.path}/${answerFile.name}`
                                        : URL.createObjectURL(answerFile)
                                      : '/img/photoplace.png'
                    return (
                      <div className={styles.answerBlock}>
                      {edit === index ? (
                      <div className={styles.editAnswerItem}>
                        <input
                          type="file"
                          id={`inputImage${i}`}
                          accept="image/png, image/jpeg"
                          onChange={setNewImageFile(i)}
                          hidden
                        />
                        <div className={styles.editAnswer}>
                          {questionData.type === 'choice' &&
                          <>
                            <input
                              className={styles.inputFirstChild}
                              value={item.order_by}
                              onChange={(e) => setAnswers(i, e, true)}
                            />
                            <div
                              className={styles.selectedBlock}
                              onClick={() => toggleAnswerCorrect(i)}
                            >
                              {!!item.is_correct && (
                                <IoIosCheckmark size={24} color="rgb(20, 99, 79)" />
                              )}
                            </div>
                          </>}
                          <input
                            className={styles.inputLastChild}
                            value={item.answer}
                            onChange={(e) => setAnswers(i, e)}
                          />
                          <IoIosCloseCircle
                            onClick={() => deleteItem(i)}
                            className={styles.deleteItem}
                            size={20}
                          />
                        </div>
                        {!data.hasOwnProperty('weight') &&
                        <input
                          value={item.points}
                          onChange={(e) => editAnswer(e, i)}
                          onWheel={(e) => e.currentTarget.blur()}
                          type="number"
                          name='points'
                          // placeholder="баллы"
                        />}
                      </div>
                    ) : (
                      <div className={styles.answerItem}>
                        <div style={item.is_correct ? { fontWeight: "500" } : null}>
                          {item.answer}
                        </div>
                        <div style={item.is_correct ? { fontWeight: "500" } : null}>
                          {item.points}
                        </div>
                      </div>
                    )}
                    {['choice', 'dnd'].includes(questionData.type) &&
                      <>
                        <div>Реакция на ответ:</div>
                        {edit === index
                          ? <textarea
                              value={data.animations[i]?.animation?.description}
                              onChange={e => handleAnimationChange(i)(e, true)}
                            />
                          : <div>{data.animations[i]?.animation?.description}</div>
                        }
                        <AnimationComponent
                          value={data.animations[i]}
                          options={animations}
                          onChange={handleAnimationChange(i)}
                          disabled={edit !== index}
                        />
                      </>
                    }
                    <div className={styles.categoryBlock}>
                      <span>Категория:</span>
                      <select
                        value={item.category}
                        onChange={(e) => editAnswer(e, i)}
                        name='category'
                        disabled={edit !== index}
                      >
                        <option value={''}>Без категории</option>
                        {categories.map(cat =>
                          <option value={cat.id}>{cat.category}</option>
                        )}
                      </select>
                    </div>
                    {(edit === index || answerFile) &&
                      <div className={styles.imageBox}>
                      {edit === index &&
                      <>
                        <IoMdCreate onClick={editImageClick(i)} />
                        <IoIosClose onClick={deleteImageClick(i)} />
                      </>}
                      <img
                        src={imageSrc}
                        alt='о_О'
                      />
                    </div>}
                    </div>
                    )
                  })}
                  {edit === index && questionData.type !== 'word' && (
                    <div>
                      <button className={styles.addButton} onClick={newItem}>
                        + добавить вариант ответа
                      </button>
                    </div>
                  )}
                </div>
              )}

              { questionData.type === 'dnd' &&
                  <div className={styles.moreWordsBlock}>
                    <div>Дополнительные слова (через запятую):</div>
                    {
                      edit === index
                        ? <textarea
                            value={data.additional_words.join(',')}
                            onChange={(e) => setData((prev) => ({ ...prev, additional_words: e.target.value.split(',') }))}
                          />
                        : <div>{data.additional_words.join(',')}</div>
                    }
                  </div>
              }

              <div className={styles.orderBlock}>
                <label>
                  Порядковый номер: 
                  {edit === index
                  ? <input
                    onWheel={(e) => e.currentTarget.blur()}
                    type='number'
                    value={data.order_by}
                    onChange={(e) => setData((prev) => ({ ...prev, order_by: e.target.value }))}
                  />
                  : <span>{data.order_by}</span>
                  }
                </label>
              </div>
              {edit === index
                ? <CustomCheckbox
                    label="вопрос является обязательным"
                    value={data.required}
                    onChange={() => setData(prev => ({...prev, required: !prev.required}))}
                  />
                : <div className={styles.requiredBlock}>
                    {`Вопрос ${data.required ? '' : 'не'} является обязательным`}
                  </div>
              }
              {questionData.type !== 'supplement' &&
                (edit === index
                  ? <CustomCheckbox
                      label="перемешивать ответы"
                      value={data.shuffle_answers}
                      onChange={() => setData(prev => ({...prev, shuffle_answers: !prev.shuffle_answers}))}
                    />
                  : <div className={styles.requiredBlock}>
                      {`Ответы в вопросе будут в ${data.shuffle_answers ? 'случайном' : 'строго определенном'} порядке`}
                    </div>
                )
              }
              {
                (edit === index
                  ? <CustomCheckbox
                      label="прикреплять геопозицию"
                      value={data.is_need_geo}
                      onChange={() => setData(prev => ({...prev, is_need_geo: !prev.is_need_geo}))}
                    />
                  : 
                  <div className={styles.requiredBlock}>
                      {`Ответы в вопросе будут ${data.is_need_geo ? 'с геопозицией' : 'без геопозиции'}`}
                    </div>
                )
              }
              {questionData.type === 'choice' &&
              <div className={styles.orderBlock}>
                <label>
                Допустимое количество ответов: 
                  {edit === index
                  ? <input
                    type='number'
                    value={data.correct_answers_count}
                    onWheel={(e) => e.currentTarget.blur()}
                    onChange={(e) => setData((prev) => ({ ...prev, correct_answers_count: parseInt(e.target.value) }))}
                  />
                  : <span>{data.correct_answers_count}</span>
                  }
                </label>
              </div>}
              {edit === index && (
                <div className={styles.buttonsBlock}>
                  <button className={styles.buttonOk} onClick={setToggleFalse}>
                    ОК
                  </button>
                  <button className={styles.buttonСancel} onClick={cancelHandle}>
                    Отмена
                  </button>
                </div>
              )}
            </div>
            {data.hasOwnProperty('weight') &&
            <div className={styles.correctAnswerBlock}>
              <div>Баллы:</div>
              <div>
                {edit === index ? (
                  <input
                    value={data.weight}
                    onChange={(e) =>
                      setData((prev) => ({ ...prev, weight: e.target.value || null }))
                    }
                    onWheel={(e) => e.currentTarget.blur()}
                    type="number"
                  />
                ) : (
                  data.weight
                )}
              </div>
            </div>}
            <div className={styles.questionType}>{questionTypes[data.type]}</div>
            <div className={styles.iconButtons}>
              {/* {fullMode && edit !== index &&
              <i
                title='Свернуть'
                className={styles.editButton}
                onClick={setQuestionNotFull}
              >
                <IoIosArrowRoundUp/>
              </i>} */}
              {canEdit && typeof(edit) !== "number" && (
                <i
                  className={styles.editButton}
                  onClick={setToggleTrue}
                  title='Редактировать'
                >
                  <IoMdCreate />
                </i>
              )}
              {canEdit &&
              <i
                title='Удалить'
                className={styles.editButton}
                onClick={() => setConfirmation(true)}
              >
                <IoIosClose/>
              </i>}
            </div>
            {confirmation &&
            <Confirmation
              confirmation="вопрос"
              acceptConfirmation={acceptConfirmation}
              rejectConfirmation={rejectConfirmation}
            />}
          </div>
        : renderQuestionPreview()
      }
    </>
  );
}
